import * as React from 'react';
import './buttonStyle.css';


export default function PrintButton(props) {

  return (
    <div className='print-button-container'>
      <button
        className="print-button"
        type="button"
        autoFocus={false}
        onClick={() => { 
          document.title = props.documentName.replaceAll("%20", " ");
          props.name  && props.name  === "Print Page" ? window.print() : props.function();
        } }
        style = {{marginTop: props.name  && props.name !== "Print Page" ? "20px" : "60px"}}
      >
        {props.name}
      </button>
    </div>

  );
}